import "./App.css";

// Importing react components/Pages ----------------------------------
import Revamp from "./components/Misc/Revamp";
import Navbar from "./Shared/Navbar";
// Importing Route requirements ----------
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Switch>
          <Route component={Revamp} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
