import React from "react";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <div className="container navbar">
      <div className="company-name">
        <div className="prudent-logo"></div>
        <div className="co-name">
          <Link to="/" className="nav-link" id="co-name">
            Prudent Solutions
          </Link>
        </div>
      </div>
      <div className="links">
        <Link to="#" className="nav-link">
          About us
        </Link>
        <Link to="#" className="nav-link">
          Services
        </Link>
        <Link to="#" className="nav-link">
          Features
        </Link>
        <Link to="#" className="nav-link">
          Contact us
        </Link>
        <div className="nav-btns-container">
          <button className="btn filled-btn" id="nav-btns">Login</button>
          <p style={{ color: "white", fontSize: "0.75rem" }}>or</p>
          <button className="btn bordered-btn" id="nav-btns">Sign up</button>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
