import React from "react";

function Revamp() {
  return (
    <div className="container full-ht revamp-cont">
        <div className="revamp-wrapper">
          <div className="revamp-img"></div>
          <div className="revamp-message">
            <h1>This website is being revamped</h1>
            <p>This website is currently unavailable. Please check back later.</p>
            <p>Sorry for the inconvienence</p>
          </div>
        </div>
    </div>
  );
}

export default Revamp;
